/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "clsx";
import React from "react";
import { Dropdown, DropdownContent, useComponentVisible } from "vapi-ui-common";
import { RefItem } from "../../../gql/generated";
import { RefItemSortInput } from "../../../models/refItem/refItem.model";
import { SortType } from "../../../models/sort.model";
import SortButton from "../SortButton";
import SortContent from "../SortContent";
import SortSelect from "../SortSelect";
import styles from "./sortDropdown.module.scss";

export interface DropdownListProps {
  buttonText: string;
  list: SortType[];
  onSelect: (item: SortType) => void;
  sortType?: SortType;
  itemList?: RefItem[];
  onSave?: (sortList: RefItem[], subSortList: RefItemSortInput[]) => void;
  setSortType?: (value: React.SetStateAction<SortType | undefined>) => void;
  sortSelectClassName?: string;
}

const SortDropdown = ({
  buttonText,
  list = [],
  onSelect,
  sortType,
  itemList,
  onSave,
  setSortType,
  sortSelectClassName,
}: DropdownListProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const onClick = (val: SortType) => {
    return () => {
      onSelect(val);
      if (val !== SortType.GENUINE_AAP && val !== SortType.PRODUCT_TYPE) {
        setIsComponentVisible(false);
      }
    };
  };

  const onClose = () => {
    setIsComponentVisible(false);
    if (setSortType) {
      setSortType(SortType.NONE);
    }
  };

  const isSortContent = (thisSortType?: SortType) => {
    return (
      thisSortType &&
      (thisSortType === SortType.GENUINE_AAP ||
        thisSortType === SortType.PRODUCT_TYPE)
    );
  };

  const getSortContent = () => {
    return isSortContent(sortType) ? (
      <SortContent
        sortType={sortType || SortType.PRODUCT_TYPE}
        itemList={itemList}
        onClose={onClose}
        onSave={onSave}
      />
    ) : (
      <SortSelect
        list={list}
        onClick={onClick}
        className={cx(sortSelectClassName)}
      />
    );
  };

  return (
    <Dropdown>
      <SortButton
        toggled={isComponentVisible}
        onClick={() => {
          if (setSortType) {
            setSortType(SortType.NONE);
          }
          setIsComponentVisible(true);
        }}
      >
        {buttonText}
        <FontAwesomeIcon className={styles.caret} icon={faAngleDown} />
      </SortButton>
      <DropdownContent open={isComponentVisible} ref={ref}>
        {getSortContent()}
      </DropdownContent>
    </Dropdown>
  );
};

export default SortDropdown;
