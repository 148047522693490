export const NATIONAL_REGION = "ntnl";
export const GST_REGION = "gst";
export const ACC_NTNL_TEAM = "NationalTeam";
export const ACC_GST_TEAM = "GSTTeam";
/** Draft Version used on Common Language */
export const DRAFT_CL = "DRAFT_CL";
export const ARCHIVED_CL = "ARCHIVED_CL";
export const ACCESSORY_TEAM = "AT";

export enum PUBLISHTYPE {
  PUBLISH = "publish",
  PREVIEW = "preview",
}

export enum FILTERTYPE {
  ACCESSORIES = "Accessories",
  MODELMATRIX = "Model Matrix",
}
