import { useEffect, useState } from "react";
import { useChecklist } from "vapi-ui-common";
import { ChecklistItem } from "vapi-ui-common/dist/src/hooks/useChecklist/useChecklist";
import VehicleModelsStore, { ModelItem } from "../stores/modelStore";
import { AppliedFilters } from "../components/AccessoryFilters/AccessoryFilters";
import { RefItem } from "../gql/generated";
import { FILTERTYPE } from "../constants/Constants";

const useAccessoryFilter = (
  modelStore: VehicleModelsStore,
  isActiveFilter: boolean,
  inProgressFilter: boolean,
  onApplyFilters: (filters: AppliedFilters) => void,
  hasDioFilter?: boolean | undefined,
  hasPpoFilter?: boolean | undefined,
  syncChangesFilter?: boolean | undefined,
  productTypes?: RefItem[] | undefined,
  productTypeFilters?: string[] | undefined
) => {
  const [selectedFilterTab, setSelectedFilterTab] = useState<string>(
    FILTERTYPE.ACCESSORIES
  );

  const [modelItemList, setModelItemlist] = useState<ModelItem[]>([]);

  const [selectedFuelTypes, setSelectedFuelTypes] = useState<string[]>([]);
  const [savedFuelTypes, setSavedFuelTypes] = useState<string[]>([]);
  const [inProgress, setInProgress] = useState(inProgressFilter);
  const [savedInProgress, setSavedInProgress] = useState(inProgressFilter);
  const [syncChanges, setSyncChanges] = useState(syncChangesFilter || false);
  const [active, setActive] = useState(isActiveFilter);
  const [savedActive, setSavedActive] = useState(isActiveFilter);
  const [dio, setDio] = useState(hasDioFilter);
  const [savedDio, setSavedDio] = useState(hasDioFilter);
  const [ppo, setPpo] = useState(hasPpoFilter);
  const [savedPpo, setSavedPpo] = useState(hasPpoFilter);
  const [savedChecklist, setSavedChecklist] = useState<ChecklistItem[]>([]);
  const [changesApplied, setChangesApplied] = useState<boolean>(false);

  const { checklist, setChecklist, isAllSelected, selectAll, selectItem } =
    useChecklist();

  useEffect(() => {
    setModelItemlist(modelStore.modelItems);
  }, [modelStore, modelStore.modelItems]);

  useEffect(() => {
    (() => {
      if (productTypeFilters && productTypes) {
        setChecklist(
          productTypes.map((item: RefItem) => ({
            id: item.id,
            name: item.name,
            selected: productTypeFilters.indexOf(item.id) !== -1,
          }))
        );
      }
    })();
  }, [productTypes, setChecklist, productTypeFilters]);

  const onApplyModelFilters = (fuelTypes: string[]) => {
    const modelDir: Record<
      string,
      Record<string, any>
    > = modelStore.data.reduce((acc, model) => {
      if (!acc[model.fuelType]) {
        acc[model.fuelType] = {};
      }
      acc[model.fuelType][model.id] = model;
      return acc;
    }, {} as Record<string, Record<string, any>>);

    let filteredModels = modelStore.modelItems;

    if (fuelTypes.length) {
      filteredModels = filteredModels.filter((item) => {
        let found = false;

        fuelTypes.forEach((fuelType) => {
          if (found) {
            return false;
          }

          if (modelDir[fuelType][item.modelId]) {
            found = true;
          }

          return found;
        });

        return found;
      });
    }

    setSavedFuelTypes(fuelTypes);
    setModelItemlist(filteredModels);
  };

  const applyFilters = () => {
    const productFilters: string[] = [];
    checklist.forEach((item) => {
      if (item.selected) {
        productFilters.push(item.id);
      }
    });
    onApplyFilters({
      productFilters,
      inProgress,
      syncChanges,
      active,
      dio,
      ppo,
    });
    setSavedChecklist(checklist);
    setSavedDio(dio);
    setSavedPpo(ppo);
    setSavedActive(active);
    setSavedInProgress(inProgress);
    setChangesApplied(true);
  };

  const onClickFilter = () => {
    applyFilters();
    onApplyModelFilters(selectedFuelTypes);
  };

  const onCancelFilter = () => {
    if (changesApplied) {
      setChecklist(savedChecklist);
      setChangesApplied(false);
    } else if (savedChecklist.length > 0) {
      setChecklist(savedChecklist);
    } else {
      selectAll(false);
      setChecklist(checklist);
    }

    setSelectedFuelTypes(savedFuelTypes);
    setDio(savedDio);
    setPpo(savedPpo);
    setActive(savedActive);
    setInProgress(savedInProgress);
  };

  return {
    modelItemList,
    selectedFilterTab,
    setSelectedFilterTab,
    checklist,
    isAllSelected,
    selectAll,
    selectItem,
    inProgress,
    setInProgress,
    active,
    setActive,
    dio,
    setDio,
    ppo,
    setPpo,
    syncChanges,
    setSyncChanges,
    selectedFuelTypes,
    setSelectedFuelTypes,
    onCancelFilter,
    onClickFilter,
  };
};

export default useAccessoryFilter;
