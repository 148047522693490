import React, { useCallback, useContext, useState } from "react";
import { TableCell } from "vapi-ui-common";
import AmsButton from "../../../../../components/AmsButton";
import CompareAccToADSModal from "../../../../../components/CompareAccToADSModal";
import CommonLanguageBadge from "../../../../../components/CommonLanguageBadge";
import { AclDocument, RefItem } from "../../../../../gql/generated";
import AccessoryItemVM from "../../../../../models/accessories/AccessoryItemVM";
import { Language } from "../../../../../models/user/user.model";
import useStores from "../../../../../stores/useStores";
import AccessoriesEntryScreenContext from "../AccessoriesEntryScreenContext";
import AccessoryRichTextLanguage from "./AccessoryRichTextLanguage";
import styles from "./TitleColumn.module.scss";

interface TitleColumnProps {
  accessories: AccessoryItemVM[];
  accessoryItem: AccessoryItemVM;
  productTypes: RefItem[];
  onSync: (item: AccessoryItemVM, newProductType: string) => Promise<void>;
  sortMode?: boolean;
}

const TitleColumn = ({
  accessories,
  accessoryItem,
  productTypes,
  onSync,
  sortMode,
}: TitleColumnProps) => {
  const {
    selectedLanguages,
    readOnly,
    userDetails,
    draftDetails,
    saveAccessoryItem,
  } = useContext(AccessoriesEntryScreenContext);
  const [openAmsModal, setOpenAmsModal] = useState<boolean>(false);
  const [amsAcc, setAmsAcc] = useState<AccessoryItemVM>();

  const onAmsClick = () => {
    setOpenAmsModal(true);
    setAmsAcc(accessoryItem);
  };

  const {
    teamStore: {
      team: { langPermissions, showCommonLanguage, allowSyncAmsAds },
    },
  } = useStores();

  const disabled = !langPermissions[Language.EN]?.canEdit || readOnly;

  const onUnlink = useCallback(() => {
    const acc = accessoryItem;
    acc.comLangId = "";
    saveAccessoryItem(acc, Language.EN);
  }, [saveAccessoryItem, accessoryItem]);

  const sCommonLanguage =
    showCommonLanguage && process.env.REACT_APP_COMMON_LANGUAGE === "true";

  const showSyncAmsAds =
    allowSyncAmsAds &&
    process.env.REACT_APP_SYNC_AMS_ADS === "true" &&
    accessoryItem.adsId;

  const onLink = useCallback(
    (aclDoc: AclDocument) => {
      const acc = accessoryItem;
      if (!aclDoc || !aclDoc.publishedVersion) {
        return;
      }
      acc.comLangId = aclDoc.sk;
      acc.comLangVersion = aclDoc.publishedVersion;
      acc.hasComLangChanges = false;
      saveAccessoryItem(acc, Language.EN);
    },
    [saveAccessoryItem, accessoryItem]
  );

  const ACLComponent = ({ language }: { language: Language }) => {
    if (language !== Language.EN || !sCommonLanguage) return null;

    return (
      <CommonLanguageBadge
        variables={{ ...userDetails, ...draftDetails }}
        productTypes={productTypes}
        accessoryItem={accessoryItem}
        disabled={disabled}
        readOnly={readOnly || !langPermissions?.[Language.EN]?.canEdit}
        onUnlink={onUnlink}
        onSync={onSync}
        accessories={accessories}
        onLink={onLink}
        sortMode={sortMode}
      />
    );
  };

  return (
    <>
      {selectedLanguages.map((language) => {
        return (
          <TableCell
            largeNat
            key={`${language}-title-column-${accessoryItem.id}`}
            className={styles.titleCell}
          >
            {!showSyncAmsAds && <ACLComponent language={language} />}
            {showSyncAmsAds && (
              <AmsButton
                onClick={onAmsClick}
                hasUpdates={accessoryItem.hasADSUpdates}
                readOnly={readOnly}
              />
            )}
            <AccessoryRichTextLanguage language={language} />
          </TableCell>
        );
      })}
      <CompareAccToADSModal
        openModal={openAmsModal}
        setOpenModal={setOpenAmsModal}
        accessory={amsAcc}
        {...userDetails}
        {...draftDetails}
        saveAccessoryItem={saveAccessoryItem}
      />
    </>
  );
};

export default TitleColumn;
