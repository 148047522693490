import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "clsx";
import React from "react";
import { Button } from "vapi-ui-common";
import styles from "./amsButton.module.scss";

export interface AmsButtonProps {
  onClick?: () => void;
  hasUpdates?: boolean | null | undefined;
  readOnly?: boolean | null | undefined;
}

const AmsButton = ({
  hasUpdates = false,
  onClick = () => undefined,
  readOnly = false,
}: AmsButtonProps) => {
  return (
    <Button
      className={cx(styles.syncAmsAdsBtn, {
        [styles.hasUpdatesBtn]: hasUpdates,
        [styles.noUpdatesBtn]: !hasUpdates,
        [styles.readOnlyBtn]: readOnly,
      })}
      onClick={onClick}
      data-testid="ams-button-comp"
      disabled={!hasUpdates || !!readOnly}
    >
      <FontAwesomeIcon
        className={cx(styles.warningIcon, { [styles.hideIcon]: !hasUpdates })}
        icon={faExclamationTriangle}
        data-testid="ams-button-comp-alert-icon"
      />
      <span data-testid="ams-button-comp-text">AMS</span>
    </Button>
  );
};

export default AmsButton;
