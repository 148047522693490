import cx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
  DroppableProvided,
} from "react-beautiful-dnd";
import { trackPromise } from "react-promise-tracker";
import ResizeDetector from "react-resize-detector";
import { Redirect, useHistory, withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { toast } from "react-toastify";
import {
  AccessoriesTableHeaderDetail,
  AccessoriesTableHeaderRow,
  AccessoriesTableTrimHeaderName,
  Button,
  Checkbox,
  Header,
  Modal,
  Spinner,
  StickyContainer,
  StickyHeaderContainer,
  StickyHeaderSection,
  Table,
  TableCell,
  TableRow,
  Thead,
  TwoTableWrapper,
  Wayfinding,
  areRichTextValuesDifferent,
  cleanUpRte,
  convertToRichTextObject,
  useDebounce,
} from "vapi-ui-common";
import { AppliedFilters } from "../../components/AccessoryFilters/AccessoryFilters";
import AccessoryRichText from "../../components/AccessoryRichText";
import BackButton from "../../components/BackButton/BackButton";
import CommonLanguageModal from "../../components/CommonLanguageModal";
import Input from "../../components/Input";
import PageError from "../../components/PageError";
import SyncAllChangesCTA, {
  SyncAllChangesActions,
  SyncAllChangesContext,
  getInitialSyncAllChangesState,
  syncAllChangesReducer,
} from "../../components/SyncAllChangesModal";
import SyncUpdatesPopover from "../../components/SyncUpdatesPopover";
import SortButton from "../../components/sortModule/SortButton";
import SortDropdown from "../../components/sortModule/SortDropdown/SortDropdown";
import RightTableSizer from "../../components/table/RightTableSizer/RightTableSizer";
import { NATIONAL_REGION } from "../../constants/Constants";
import {
  ModelApplicabilityItem,
  RefItem,
  useCreateProductTypeMutation,
  usePublishAccessoryDraftMutation,
  useUpdateAclSyncMutation,
  useUpdateAdsSyncMutation,
  useUpdateSpanishSyncMutation,
} from "../../gql/generated";
import useAccessoryFilter from "../../hooks/useAccessoryFilter";
import useInactiveMapper from "../../hooks/useInactiveMapper";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import { RefItemSortInput } from "../../models/refItem/refItem.model";
import { RouteParams } from "../../models/routes.model";
import { SortType } from "../../models/sort.model";
import { Language } from "../../models/user/user.model";
import { VehicleTeam } from "../../models/vehicleData/vehicleData.model";
import useStores from "../../stores/useStores";
import handleErrorResponse from "../../utils/errorHandlingUtils";
import { getSortPayload } from "../../utils/sortUtils";
import { getVersionInfoFromParams } from "../../utils/vehicleDataUtils";
import AccessoriesEntryScreenService from "./AccessoriesEntryScreenService";
import AccessoriesEntryScreenTable, {
  ActionsColumn,
  ActionsHeader,
  CCopyColumn,
  CCopyHeader,
  CDisclosureColumn,
  CLexusDealerCostColumn,
  CMsrpFlagsColumn,
  CNatDealerCostColumn,
  CProductTypeColumn,
  CWarrantyColumn,
  TitleColumn,
  TitleHeader,
} from "./components/AccessoriesEntryScreenTable";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "./components/AccessoriesEntryScreenTable/AccessoriesEntryScreenContext";
import ActionBarAccessories from "./components/ActionBarAccessories";
import CheckboxCell from "./components/CheckboxCell";
import GoLiveDate from "./components/GoLiveDate";
import LanguagesCheckbox from "./components/LanguagesCheckbox";
import useTeamLanguagesCheckbox from "./components/LanguagesCheckbox/useTeamLanguagesCheckbox";
import FilterDropdown from "../../components/FilterDropdown";
import gstStyles from "../gst/accessories.module.scss";
import styles from "./AccessoriesEntryScreen.module.scss";

const AccessoriesEntryScreenController = () => {
  const params: RouteParams = useParams();
  const { modelStore, userStore, tableSizeStore, teamStore } = useStores();

  const [stateSyncAllChanges, dispatchSyncAllChanges] = useReducer(
    syncAllChangesReducer,
    getInitialSyncAllChangesState()
  );

  const teamLanguages = teamStore.team.languages;
  const {
    languagesCheckbox,
    setLanguageCheckboxSelected,
    getLanguageSelected,
  } = useTeamLanguagesCheckbox({ languages: teamLanguages });

  const isSpanish = params.team === VehicleTeam.SPANISH;
  const teamLang = isSpanish ? Language.ES : Language.EN;
  const vdVersionInfo = getVersionInfoFromParams(params.version);
  const version = (vdVersionInfo[teamLang] as string) || "DRAFT";

  const userDetails = {
    brand: userStore.brand,
    team: "AT",
    region: NATIONAL_REGION,
    lang: teamLang.toLowerCase(),
  };

  const draftDetails = {
    seriesId: params.seriesId,
    modelYear: Number(params.year),
    version,
  };

  const {
    allData,
    filteredData,
    productTypes,
    error,
    isLoaded,
    readOnly,
    setSort,
    updateSort,
    updateProductTypeSort,
    forceUpdate,
    addEmptyItem,
    addCopiedItem,
    addAccessoryItem,
    deleteItem,
    deleteAccessoryItem,
    updateAccessoryItem,
    filterItems,
    addProductType,
    sortByTitle,
    sortByGenuine,
    sortByAAP,
    sortBySortOrder,
    setDefaultLanguage,
    enCurrentVersion,
    enSourceVersion,
    lastSyncDate,
    lastNATPublishedDate,
    reloadDraft,
    accessoriesLangMap,
    productTypeLangMap,
    addNewAccessories,
    commonLanguageUpdatedIds,
    cleanCommonLanguageUpdatedIds,
    addProductTypes,
    updateGoLiveDates,
    goLiveDateLangEnMapData,
    hasADSUpdates,
    setHasADSUpdates,
  } = AccessoriesEntryScreenService({
    variables: { ...userDetails, ...draftDetails },
    vdVersionInfo,
  });

  const canEditEN =
    !!teamStore.team.langPermissions?.[Language.EN]?.canEdit && !readOnly;
  const cantEditEN = !canEditEN;
  const canEditCurLang =
    !!teamStore.team.langPermissions?.[teamLang]?.canEdit && !readOnly;

  const { inactiveMappersRef, setInactiveMappers } = useInactiveMapper();

  const [publishDraft] = usePublishAccessoryDraftMutation();
  const [updateSpanishSync] = useUpdateSpanishSyncMutation();
  const [updateAclSync] = useUpdateAclSyncMutation();
  const [updateAmsSync] = useUpdateAdsSyncMutation();
  const [createProductType] = useCreateProductTypeMutation();

  const [, setLeftPosition] = useState(0);
  const [vehiclesLoading, setVehiclesLoading] = useState(true);
  const [publishRedirect, setPublishRedirect] = useState(false);
  const [canPublish, setCanPublish] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);

  // search and filters
  const [searchText, setSearchText] = useState("");
  const [productTypeFilters, setProductTypeFilters] = useState<string[]>([]);
  const [inProgressFilter, setInProgressFilter] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [hasDioFilter, setHasDioFilter] = useState(false);
  const [hasPpoFilter, setHasPpoFilter] = useState(false);

  const [syncChangesFilter, setSyncChangesFilter] = useState(false);

  // Sorting
  const [sortMode, setSortMode] = useState(false);
  const [openProductTypeModal, setOpenProductTypeModal] = useState(false);

  const [showCommonLanguageModal, setShowCommonLanguageModal] = useState(false);
  const [sortType, setSortType] = useState<SortType | undefined>(undefined);

  // Routing
  const history = useHistory();

  const ref = useRef<HTMLDivElement>(null);
  const { debounce } = useDebounce({ delay: 2000 });

  const handleApplyFilters = (
    filters: string[],
    inProgressFilterParam: boolean,
    syncChangesFilterParam: boolean,
    isActiveFilterParam: boolean,
    dioFilterParam: boolean,
    ppoFilterParam: boolean
  ) => {
    setInProgressFilter(inProgressFilterParam);
    setSyncChangesFilter(syncChangesFilterParam);
    setIsActiveFilter(isActiveFilterParam);
    setHasDioFilter(dioFilterParam);
    setHasPpoFilter(ppoFilterParam);
    setProductTypeFilters(filters);
    filterItems(
      searchText,
      filters,
      inProgressFilterParam,
      syncChangesFilterParam,
      isActiveFilterParam,
      dioFilterParam,
      ppoFilterParam
    );
  };

  const onApplyFilters = ({
    productFilters = [],
    inProgress = false,
    syncChanges = false,
    active = false,
    dio = false,
    ppo = false,
  }: AppliedFilters) => {
    handleApplyFilters(
      productFilters,
      inProgress,
      syncChanges,
      active,
      dio,
      ppo
    );
  };

  const {
    modelItemList,
    selectedFilterTab,
    setSelectedFilterTab,
    checklist,
    isAllSelected,
    selectAll,
    selectItem,
    inProgress,
    setInProgress,
    active,
    setActive,
    dio,
    setDio,
    ppo,
    setPpo,
    syncChanges,
    setSyncChanges,
    selectedFuelTypes,
    setSelectedFuelTypes,
    onCancelFilter,
    onClickFilter,
  } = useAccessoryFilter(
    modelStore,
    isActiveFilter,
    inProgressFilter,
    onApplyFilters,
    hasDioFilter,
    hasPpoFilter,
    syncChangesFilter,
    productTypes,
    productTypeFilters
  );

  useEffect(() => {
    teamStore.setTeam(params.team, userStore.brand, userStore.langPermissions);
    setDefaultLanguage(teamStore.team.defaultLanguage);
    setVehiclesLoading(true);
    modelStore.reset();
    (async () => {
      try {
        await modelStore.init(params.seriesId, params.year, userStore.brand);
        setCanPublish(modelStore.data.length > 0);
      } catch (e) {
        const errorMessage = e.response?.data?.message || "";
        if (errorMessage.indexOf("No vehicle data") === -1) {
          handleErrorResponse(e);
        }
      } finally {
        setVehiclesLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    setLeftPosition(ref.current?.getBoundingClientRect().left || 0);
  }, [ref]);

  useEffect(() => {
    dispatchSyncAllChanges({
      type: SyncAllChangesActions.SET_MODEL_MAP,
      payload: {
        modelItemList,
      },
    });
  }, [modelItemList]);

  const onResize = (width: number, height: number) => {
    tableSizeStore.tableRowHeight = height;
  };

  const handleOpenCommonLanguageModal = () => {
    setShowCommonLanguageModal(!showCommonLanguageModal);
  };

  const handleSearchFilter = (query: string) => {
    setSearchText(query);
    filterItems(query, productTypeFilters, inProgressFilter, syncChangesFilter);
  };

  const onStopSorting = async () => {
    setOpenProductTypeModal(false);
    setSortMode(false);
    try {
      await updateSort(false, allData);
    } catch (e) {
      handleErrorResponse(e, "Error updating accessory sort");
    }
  };

  const saveAccessoryItem = async (
    item: AccessoryItemVM,
    language: Language = Language.EN
  ) => {
    forceUpdate();
    debounce(async () => {
      try {
        // if an item has a revId then we know it is already in the database. a spanish accessory that isnt in the databse would have an id, the same as its english counterpart, but not a revId
        if (item.revId) {
          await trackPromise(updateAccessoryItem(item, language));
          toast.success("Successfully updated accessory");
          setInactiveMappers(
            inactiveMappersRef.current.filter((ele) => ele.id !== item.id)
          );
        } else {
          await trackPromise(addAccessoryItem(item));
          toast.success("Successfully added accessory");
        }
      } catch (e) {
        handleErrorResponse(e);
        if (item.id) {
          setInactiveMappers(
            inactiveMappersRef.current.filter((ele) => ele.id !== item.id)
          );
        }
      }
    }, item.uid);
  };

  const onSyncUpdates = async () => {
    await trackPromise(
      updateAclSync({
        variables: {
          input: {
            ...userDetails,
            seriesId: draftDetails.seriesId,
            modelYear: draftDetails.modelYear,
          },
        },
        update: (_cache, { data: newData }) => {
          if (!newData) return;

          const updates =
            newData.updateACLSync.accessories?.reduce<
              Map<
                string,
                {
                  revId?: string;
                  comLangId?: string;
                  comLangVersion?: number;
                  hasComLangChanges?: boolean;
                }
              >
            >((acc, item) => {
              acc.set(item.id, {
                revId: item.revId ?? undefined,
                comLangId: item.comLangId ?? undefined,
                comLangVersion: item.comLangVersion ?? undefined,
                hasComLangChanges: item.hasComLangChanges ?? undefined,
              });
              return acc;
            }, new Map()) ?? new Map();

          allData.forEach((acc) => {
            const update = updates.get(acc.id);

            if (!update) return;

            acc.revId = update.revId;
            acc.comLangId = update.comLangId;
            acc.comLangVersion = update.comLangVersion;
            acc.hasComLangChanges = update.hasComLangChanges;
          });

          cleanCommonLanguageUpdatedIds();
        },
      })
    );
  };

  const onSyncAMSUpdates = async () => {
    await trackPromise(
      updateAmsSync({
        variables: {
          input: {
            ...userDetails,
            seriesId: draftDetails.seriesId,
            modelYear: draftDetails.modelYear,
          },
        },
        update: (_cache, { data: newData }) => {
          if (!newData) return;

          const updates =
            newData.updateADSSync.accessories?.reduce<
              Map<
                string,
                {
                  revId?: string;
                  hasADSUpdates?: boolean;
                }
              >
            >((acc, item) => {
              acc.set(item.id, {
                revId: item.revId ?? undefined,
                hasADSUpdates: item.hasADSUpdates ?? undefined,
              });
              return acc;
            }, new Map()) ?? new Map();

          allData.forEach((acc) => {
            const update = updates.get(acc.id);

            if (!update) return;

            acc.revId = update.revId;
            acc.hasADSUpdates = update.hasADSUpdates;
          });
          setHasADSUpdates(false);
        },
      })
    );
  };

  const saveSyncAccessory = async (
    item: AccessoryItemVM,
    newProductType: string
  ) => {
    forceUpdate();
    const newAcc = item;
    try {
      if (newProductType) {
        await trackPromise(
          createProductType({
            variables: {
              ...userDetails,
              ...draftDetails,
              name: newProductType,
            },
            update: (_cache, { data: newData }) => {
              addProductType(newData?.createRefItem.refItem as RefItem);
              if (newData) {
                newAcc.productType = newData.createRefItem.refItem.id;
              }
            },
          })
        );
      }
      await trackPromise(updateAccessoryItem(newAcc, Language.EN));
      toast.success("Successfully updated item");
      setInactiveMappers(
        inactiveMappersRef.current.filter((ele) => ele.id !== item.id)
      );
    } catch (e) {
      handleErrorResponse(e);
      if (item.id) {
        setInactiveMappers(
          inactiveMappersRef.current.filter((ele) => ele.id !== item.id)
        );
      }
    }
  };

  const handleAddEmptyItem = () => {
    addEmptyItem();
  };

  const handleAddItemByParts = async (item: AccessoryItemVM) => {
    try {
      addNewAccessories([item]);
      await addAccessoryItem(item);
      toast.success("Successfully added ADS part to Accessories");
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const toggleAll = (item: AccessoryItemVM) => {
    const newModelApp: ModelApplicabilityItem[] = [];
    const filteredModels = modelItemList;
    modelStore.data.forEach((model) => {
      const foundModel = filteredModels.find(
        (filteredModel) => filteredModel.modelId === model.id
      );

      const isChecked =
        (item.modelApplicability &&
          item.modelApplicability.some(
            (maItem) => maItem.modelId === model.id
          )) ||
        false;

      if (foundModel || isChecked) {
        newModelApp.push({ modelId: model.id } as ModelApplicabilityItem);
      }
    });
    const acc = item;
    acc.modelApplicability = newModelApp;
    saveAccessoryItem(acc);
  };

  const handleOnPublish = async () => {
    try {
      await trackPromise(
        publishDraft({
          variables: {
            ...userDetails,
            seriesId: params.seriesId || "",
            modelYear: Number(params.year),
          },
        })
      );
      toast.success("Successfully certified draft");
      setPublishRedirect(true);
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const installPointOnchange = (item: AccessoryItemVM, point: string) => {
    const acc = item;
    const installPoint =
      acc.installPoint?.split(",").filter((iPoint) => iPoint.length > 0) || [];
    if (installPoint && installPoint.includes(point)) {
      const index = installPoint.indexOf(point);
      installPoint.splice(index, 1);
    } else {
      installPoint.push(point);
    }

    acc.installPoint = (installPoint?.length && installPoint?.join()) || "";
    saveAccessoryItem(acc);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }
    // we need to update allData instead of filteredData, so sorted doesn't get lost when filter updates
    const sourceIndex = allData.findIndex(
      (x) => x.uid === filteredData[result.source.index].uid
    );
    const destinationIdex = allData.findIndex(
      (x) => x.uid === filteredData[result.destination?.index ?? -1].uid
    );
    const [removed] = allData.splice(sourceIndex, 1);

    allData.splice(destinationIdex, 0, removed);
    allData.forEach((item: { sortOrder: number | string }, index: number) => {
      const acc = item;
      acc.sortOrder = index + 1;
    });
    filterItems("", [], false, false); // forces ui to re-render correctly and update filteredData
    // onDragEnd requires us to return at least something
    return allData;
  };

  const errorOnDuplicatePPOModelApplicability = (): AccessoryItemVM[] => {
    const errorAcc: AccessoryItemVM[] = [];
    const map: { [ppoCode: string]: { [modelCode: string]: true } } = {};
    const modelIdMap: { [modelId: string]: true } = {};
    modelStore.modelItems.forEach((modelItem) => {
      modelIdMap[modelItem.modelId] = true;
    });
    filteredData.forEach((item) => {
      if (item.ppoCode) {
        const parsedPPO = JSON.parse(item.ppoCode.toLowerCase()).text;
        if (!map[parsedPPO]) {
          map[parsedPPO] = {};
        }
        if (item.modelApplicability) {
          item.modelApplicability.forEach((model) => {
            if (modelIdMap[model.modelId]) {
              if (!map[parsedPPO][model.modelId]) {
                map[parsedPPO][model.modelId] = true;
              } else {
                errorAcc.push(item);
              }
            }
          });
        }
      }
    });
    return errorAcc;
  };

  const hasInstallPointError = (): AccessoryItemVM[] => {
    const errorAcc: AccessoryItemVM[] = [];
    allData.forEach((item) => {
      if (
        item.installPoint?.toLowerCase() !== "ppo" &&
        item.installPoint?.toLowerCase() !== "dio"
      ) {
        errorAcc.push(item);
      }
    });
    return errorAcc;
  };

  const hasChangedAttributesError = (): AccessoryItemVM[] => {
    const errorAcc: AccessoryItemVM[] = [];
    Object.values(accessoriesLangMap).forEach((accLangMap) => {
      Object.values(accLangMap.langs).forEach((acc) => {
        if (acc.changedAttributes?.length) {
          errorAcc.push(acc);
        }
      });
    });
    return errorAcc;
  };

  const hasCertifyValidationErrors = (): boolean => {
    const getTextValue = (rteValue: string): string => {
      return convertToRichTextObject(rteValue).text;
    };

    // Perform duplicate PPO code model applicability check
    const errorDuplicatePPOModelApplicabilityAccs: AccessoryItemVM[] =
      errorOnDuplicatePPOModelApplicability();
    if (errorDuplicatePPOModelApplicabilityAccs.length) {
      toast.error(
        `Please remove duplicate model applicabilities with the same PPO Codes for accessory - ${getTextValue(
          errorDuplicatePPOModelApplicabilityAccs[0].title
        )}`
      );
      return true;
    }

    // Perform install points vaidation
    const errorInstallPointsAccs: AccessoryItemVM[] = hasInstallPointError();
    if (errorInstallPointsAccs.length) {
      toast.error(
        `Please select install point for accessory - ${getTextValue(
          errorInstallPointsAccs[0].title
        )}`
      );
      return true;
    }

    // Perform changed attributes validation
    const errorChangedAttributesAccs: AccessoryItemVM[] =
      hasChangedAttributesError();
    if (errorChangedAttributesAccs.length) {
      toast.error(
        `Please review changes for accessory - ${getTextValue(
          errorChangedAttributesAccs[0].title
        )}`
      );
      return true;
    }

    // Perform sort mode validation
    if (sortMode) {
      toast.error("You have unsaved sort. Save or Cancel to Certify.");
      return true;
    }

    return false;
  };

  const handleUpdateSpanishSync = async () => {
    setIsSyncing(true);
    try {
      await trackPromise(
        updateSpanishSync({
          variables: {
            brand: userDetails.brand,
            team: userDetails.team,
            region: userDetails.region,
            seriesId: draftDetails.seriesId,
            modelYear: draftDetails.modelYear,
          },
          update: (_cache, { data: newData }) => {
            const newSourceVersion =
              newData?.spanishSync.document.sourceVersion;
            setIsSyncing(false);
            const url = `/vehicleData/${params.team}/accessories/${params.seriesId}/${params.year}/${params.series}/EN:${newSourceVersion}|ES:DRAFT`;
            history.push(encodeURI(url));
            reloadDraft(
              (newSourceVersion || vdVersionInfo[Language.EN] || "1").toString()
            );
            toast.success("Sync Successful");
          },
        })
      );
    } catch (e) {
      handleErrorResponse(e, "Error with Sync");
      setIsSyncing(false);
    }
  };

  const handleAcceptChanges = async (item: AccessoryItemVM) => {
    if (item.id) {
      try {
        await trackPromise(updateAccessoryItem(item, Language.ES, true));
        toast.success("Successfully updated accessory");
      } catch (e) {
        handleErrorResponse(e, "Error with update");
      }
    }
  };

  const onSaveSort = async (
    sortList: RefItem[],
    subSortList: RefItemSortInput[]
  ) => {
    try {
      const copy = allData.slice();
      const sortedAccessory: AccessoryItemVM[] = [];

      if (sortType === SortType.PRODUCT_TYPE) {
        subSortList.forEach((item) => {
          const newAccessoryArray = copy.filter(
            (acc) => acc.productType === item.item.id
          );

          if (item.subSortTypes[0] === SortType.ALPHABETICAL) {
            sortedAccessory.push(...newAccessoryArray.sort(sortByTitle));
          } else if (item.subSortTypes[0] === SortType.GENUINE) {
            sortedAccessory.push(...newAccessoryArray.sort(sortByGenuine));
          } else if (item.subSortTypes[0] === SortType.AAP) {
            sortedAccessory.push(...newAccessoryArray.sort(sortByAAP));
          }
        });
      } else if (sortType === SortType.GENUINE_AAP) {
        subSortList.forEach((item) => {
          let newAccessoryArray: AccessoryItemVM[] = [];

          if (item.item.name === SortType.AAP) {
            newAccessoryArray = copy.filter((acc) => acc.isNonGenAccessory);
          } else if (item.item.name === SortType.GENUINE) {
            newAccessoryArray = copy.filter((acc) => !acc.isNonGenAccessory);
          }

          if (item.subSortTypes[0] === SortType.ALPHABETICAL) {
            sortedAccessory.push(...newAccessoryArray.sort(sortByTitle));
          } else {
            const miniList: AccessoryItemVM[] = [];

            const noProductTypeList = newAccessoryArray.filter(
              (row) => !row.productType
            );

            if (noProductTypeList) {
              miniList.push(...noProductTypeList);
            }

            item.subSortTypes.forEach((name) => {
              const productId = productTypes.find(
                (product) => product.name === name
              )?.id;

              miniList.push(
                ...newAccessoryArray
                  .filter((newItem) => newItem.productType === productId)
                  .sort(sortByTitle)
              );
            });

            sortedAccessory.push(...miniList);
          }
        });
      }

      const payload = getSortPayload(
        sortedAccessory.length ? sortedAccessory : copy
      );

      if (payload) {
        // update the sort order
        allData.forEach((item: AccessoryItemVM) => {
          if (payload.sortList[item.id] !== undefined) {
            const acc = item;
            acc.sortOrder = payload.sortList[item.id] + 1;
          }
        });
        // last argument is set to true b/c we need to update the data array(s) in the AccessoryStore
        updateSort(false, allData.slice().sort(sortBySortOrder), true);

        if (sortType === SortType.PRODUCT_TYPE) {
          updateProductTypeSort(sortList);
        }
      }
    } catch (e) {
      handleErrorResponse(e, "Error updating category sort");
    }
  };

  const accessoriesEntryScreenContext = useMemo(
    () => ({
      userDetails,
      draftDetails,
      languagePermissions: teamStore.team.langPermissions,
      teamLang,
      readOnly,
      sortMode,
      allData,
      selectedLanguages: getLanguageSelected(),
      deleteItem,
      deleteAccessoryItem,
      addCopiedItem,
      filterItems,
      saveAccessoryItem,
      accessoriesLangMap,
      productTypeLangMap,
      setSort,
      addProductType,
      isLexusUser: userStore.isLexusUser(),
      isNationalUser: userStore.isNationalUser(),
      isSpanish,
      gradesData: modelStore.gradesData || [],
      updateGoLiveDates,
      goLiveDateLangEnMapData,
      modelItemList,
    }),
    [
      userDetails,
      draftDetails,
      teamStore.team.langPermissions,
      teamLang,
      readOnly,
      sortMode,
      allData,
      getLanguageSelected,
      deleteItem,
      deleteAccessoryItem,
      addCopiedItem,
      filterItems,
      saveAccessoryItem,
      accessoriesLangMap,
      productTypeLangMap,
      setSort,
      addProductType,
      userStore,
      modelStore.gradesData,
      isSpanish,
      updateGoLiveDates,
      goLiveDateLangEnMapData,
      modelItemList,
    ]
  );

  if (publishRedirect) {
    return <Redirect to={`/${params.team}/dashboard`} />;
  }

  if (!isLoaded || vehiclesLoading || isSyncing) {
    return <Spinner />;
  }

  if (error) {
    return <PageError />;
  }

  const showCommonLanguage =
    teamStore.team.showCommonLanguage &&
    process.env.REACT_APP_COMMON_LANGUAGE === "true" &&
    !!commonLanguageUpdatedIds.length &&
    !readOnly &&
    !!teamStore.team.langPermissions?.[Language.EN]?.canEdit;

  const showSyncAdmin =
    teamStore.team.showSyncAdmin &&
    process.env.REACT_APP_SYNC_ADMIN === "true" &&
    !readOnly &&
    !!teamStore.team.langPermissions?.[Language.EN]?.canEdit;

  const showSyncAmsAds =
    teamStore.team.allowSyncAmsAds &&
    process.env.REACT_APP_SYNC_AMS_ADS === "true" &&
    !readOnly &&
    !!teamStore.team.langPermissions?.[Language.EN]?.canEdit &&
    hasADSUpdates;

  return (
    <AccessoriesEntryScreenContext.Provider
      value={accessoriesEntryScreenContext}
    >
      <StickyContainer>
        <StickyHeaderContainer>
          <Header
            moduleTitle="Accessories"
            moduleSubTitle={
              version === "DRAFT" ? "Draft" : `Published V${version}`
            }
          />
          {params.year && params.series && params.seriesId && (
            <Wayfinding
              year={`${params.year} `}
              seriesName={params.series}
              renderBackButton={<BackButton to={`/${params.team}/dashboard`} />}
            />
          )}
          <ActionBarAccessories
            readOnly={!canEditCurLang}
            allowAddDeleteData={teamStore.team.allowAddDeleteData}
            changeLogLink={`/vehicleData/${params.team}/changelog/${params.seriesId}/${params.year}/${params.series}/${params.version}?exitVersion=${params.version}`}
            onAdd={handleAddEmptyItem}
            onAddByParts={handleAddItemByParts}
            onSearch={handleSearchFilter}
            onPublish={handleOnPublish}
            onOpenCommonLanguage={handleOpenCommonLanguageModal}
            hasCertifyValidationErrors={hasCertifyValidationErrors}
            canPublish={canPublish}
            renderFilter={(onClose) => (
              <FilterDropdown
                onClose={onClose}
                modelStore={modelStore}
                isSpanish={isSpanish}
                selectedFilterTab={selectedFilterTab}
                setSelectedFilterTab={setSelectedFilterTab}
                checklist={checklist}
                isAllSelected={isAllSelected}
                selectAll={selectAll}
                selectItem={selectItem}
                inProgress={inProgress}
                setInProgress={setInProgress}
                active={active}
                setActive={setActive}
                dio={dio}
                setDio={setDio}
                ppo={ppo}
                setPpo={setPpo}
                syncChanges={syncChanges}
                setSyncChanges={setSyncChanges}
                selectedFuelTypes={selectedFuelTypes}
                setSelectedFuelTypes={setSelectedFuelTypes}
                onCancelFilter={onCancelFilter}
                onClickFilter={onClickFilter}
              />
            )}
            renderButtons={
              <>
                {(sortMode || openProductTypeModal) &&
                  canEditCurLang &&
                  teamStore.team.allowAddDeleteData && (
                    <SortButton toggled onClick={onStopSorting}>
                      Stop Sorting
                    </SortButton>
                  )}

                {!sortMode &&
                  !openProductTypeModal &&
                  canEditCurLang &&
                  teamStore.team.allowAddDeleteData && (
                    <SortDropdown
                      buttonText="Sort"
                      list={[
                        SortType.ROWS,
                        SortType.PRODUCT_TYPE,
                        SortType.ALPHABETICAL,
                        SortType.GENUINE_AAP,
                      ]}
                      onSelect={(value) => {
                        switch (value) {
                          case SortType.ROWS: {
                            setSortMode(true);
                            break;
                          }
                          case SortType.PRODUCT_TYPE: {
                            setSortType(SortType.PRODUCT_TYPE);
                            break;
                          }
                          case SortType.ALPHABETICAL: {
                            updateSort(true, allData.slice().sort(sortByTitle));
                            filterItems("", [], false, false);
                            break;
                          }
                          case SortType.GENUINE_AAP: {
                            setSortType(SortType.GENUINE_AAP);
                            break;
                          }
                          default:
                            break;
                        }
                      }}
                      sortType={sortType}
                      setSortType={setSortType}
                      itemList={productTypes}
                      onSave={onSaveSort}
                      sortSelectClassName={styles.sortSelectClassName}
                    />
                  )}

                <LanguagesCheckbox
                  languages={teamLanguages}
                  languagesCheckbox={languagesCheckbox}
                  setLanguageCheckboxSelected={setLanguageCheckboxSelected}
                />

                {isSpanish && canEditCurLang && (
                  <SyncUpdatesPopover
                    enCurrentVersion={enCurrentVersion}
                    enSourceVersion={enSourceVersion}
                    lastSyncDates={lastSyncDate}
                    lastNATPublishedDate={lastNATPublishedDate}
                    handleUpdateSpanishSync={handleUpdateSpanishSync}
                  />
                )}

                {Boolean(showCommonLanguage) && (
                  <Button
                    variant="primary"
                    onClick={onSyncUpdates}
                    data-testid="cta-sync-cl-updates"
                  >
                    Sync CL Updates
                  </Button>
                )}

                {showSyncAmsAds && (
                  <Button
                    variant="primary"
                    onClick={onSyncAMSUpdates}
                    data-testid="cta-sync-ams-updates"
                  >
                    Sync AMS Updates
                  </Button>
                )}

                {showSyncAdmin && (
                  <SyncAllChangesContext.Provider
                    value={{
                      state: stateSyncAllChanges,
                      dispatch: dispatchSyncAllChanges,
                    }}
                  >
                    <SyncAllChangesCTA />
                  </SyncAllChangesContext.Provider>
                )}
              </>
            }
          />
        </StickyHeaderContainer>

        <Modal
          size="auto"
          open={showCommonLanguageModal}
          onClose={() => setShowCommonLanguageModal(false)}
        >
          <CommonLanguageModal
            close={() => setShowCommonLanguageModal(false)}
            addNewAccessories={addNewAccessories}
            accessories={allData}
            addNewProductTypes={addProductTypes}
          />
        </Modal>

        <TwoTableWrapper className={gstStyles.threeTable}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Table className={cx(gstStyles.stickySection, styles.accTable)}>
              <Thead
                className={gstStyles.removeBorderRight}
                style={{ height: `${tableSizeStore.tableRowHeight}px` }}
              >
                <TableRow>
                  <ActionsHeader />

                  <TitleHeader sortMode={sortMode} />

                  <CCopyHeader sortMode={sortMode} />
                </TableRow>
              </Thead>
              <Droppable
                droppableId="accessoriesDroppable"
                isDropDisabled={!sortMode}
              >
                {(provided: DroppableProvided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {filteredData.map(
                      (item: AccessoryItemVM, index: number) => {
                        return (
                          <Draggable
                            key={item.uid}
                            draggableId={item.uid}
                            index={index}
                          >
                            {(draggableProvided: DraggableProvided) => (
                              <TableRow
                                key={item.uid}
                                innerRef={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                              >
                                <AccessoryContext.Provider
                                  value={{
                                    index,
                                    accessoryItem: item,
                                  }}
                                >
                                  <ActionsColumn
                                    draggableProvided={draggableProvided}
                                    handleAcceptChanges={handleAcceptChanges}
                                  />

                                  <TitleColumn
                                    accessories={allData}
                                    accessoryItem={item}
                                    productTypes={productTypes}
                                    onSync={saveSyncAccessory}
                                    sortMode={sortMode}
                                  />

                                  <CCopyColumn />
                                </AccessoryContext.Provider>
                              </TableRow>
                            )}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </Table>

            <AccessoriesEntryScreenTable
              setSort={setSort}
              tHeadStyle={{ height: `${tableSizeStore.tableRowHeight}px` }}
            >
              {filteredData.map((item, index: number) => (
                <AccessoryContext.Provider
                  key={item.uid}
                  value={{
                    index,
                    accessoryItem: item,
                  }}
                >
                  <TableRow className={styles.row}>
                    {/* Disclosures */}
                    <CDisclosureColumn />

                    <CProductTypeColumn />

                    {/* Install Point */}
                    <TableCell large className={styles.installPointCell}>
                      <Checkbox
                        id={`ppo${item.uid}`}
                        defaultChecked={item.installPoint?.includes("PPO")}
                        onChange={() => installPointOnchange(item, "PPO")}
                        disabled={cantEditEN}
                      >
                        <span className={styles.installPointLabels}>PPO</span>
                      </Checkbox>
                      <Checkbox
                        id={`dio${item.uid}`}
                        checked={item.installPoint?.includes("DIO")}
                        onChange={() => installPointOnchange(item, "DIO")}
                        disabled={cantEditEN}
                      >
                        <span className={styles.installPointLabels}>DIO</span>
                      </Checkbox>
                    </TableCell>
                    {/* PPO Code */}
                    <TableCell large>
                      <AccessoryRichText
                        tabIndex={index + 1}
                        value={item.ppoCode || ""}
                        maxLength={2}
                        disabled={cantEditEN}
                        onBlur={(ppoCode) => {
                          if (
                            areRichTextValuesDifferent(
                              item.ppoCode || "",
                              ppoCode
                            )
                          ) {
                            const acc = item;
                            acc.ppoCode = cleanUpRte(ppoCode);
                            saveAccessoryItem(acc);
                          }
                        }}
                      />
                    </TableCell>

                    <CWarrantyColumn />

                    {/* Required/Conflicts */}
                    <TableCell large>
                      <Input
                        id="accessory-required-input"
                        label="Required"
                        disabled={cantEditEN}
                        defaultValue={item.required || ""}
                        onChange={(event) => {
                          const val = event.target.value;
                          if (
                            areRichTextValuesDifferent(item.required || "", val)
                          ) {
                            const acc = item;
                            acc.required = val;
                            saveAccessoryItem(acc);
                          }
                        }}
                      />
                      <div className={styles.separator} />
                      <Input
                        id="accessory-conflicts-input"
                        label="Conflicts"
                        disabled={cantEditEN}
                        defaultValue={item.conflicts || ""}
                        onChange={(event) => {
                          const val = event.target.value;
                          if (
                            areRichTextValuesDifferent(
                              item.conflicts || "",
                              val
                            )
                          ) {
                            const acc = item;
                            acc.conflicts = val;
                            saveAccessoryItem(acc);
                          }
                        }}
                      />
                    </TableCell>
                    {/* Part Number */}
                    <TableCell large>
                      <AccessoryRichText
                        tabIndex={index + 1}
                        value={item.partNumber || ""}
                        disabled={cantEditEN}
                        onBlur={(partNumber) => {
                          if (
                            areRichTextValuesDifferent(
                              item.partNumber || "",
                              partNumber
                            )
                          ) {
                            const acc = item;
                            acc.partNumber = cleanUpRte(partNumber);
                            saveAccessoryItem(acc);
                          }
                        }}
                      />
                    </TableCell>

                    {/* Dealer Cost */}
                    <CNatDealerCostColumn />

                    {/* Dealer Cost Lexus */}
                    <CLexusDealerCostColumn />

                    {/* Parts Only MSRP */}
                    {userStore.isToyotaNationalUser() && (
                      <TableCell large>
                        <AccessoryRichText
                          tabIndex={index + 1}
                          value={item.partsOnlyMSRP || ""}
                          disabled={cantEditEN}
                          onBlur={(partsOnlyMSRP) => {
                            if (
                              areRichTextValuesDifferent(
                                item.partsOnlyMSRP || "",
                                partsOnlyMSRP
                              )
                            ) {
                              const acc = item;
                              acc.partsOnlyMSRP = cleanUpRte(partsOnlyMSRP);
                              saveAccessoryItem(acc);
                            }
                          }}
                        />
                      </TableCell>
                    )}

                    {/* MSRP, In Progress, Notes */}
                    <CMsrpFlagsColumn />
                  </TableRow>
                </AccessoryContext.Provider>
              ))}
            </AccessoriesEntryScreenTable>
          </DragDropContext>
          <ScrollSync>
            <RightTableSizer>
              <StickyHeaderSection>
                <ScrollSyncPane group="horizontal">
                  <div ref={null} style={{ overflowX: "auto" }}>
                    <Table>
                      <Thead>
                        <tr>
                          <AccessoriesTableHeaderRow>
                            {modelItemList.map((model) => (
                              <AccessoriesTableTrimHeaderName
                                className={styles.modelApplicabilityGradeHeader}
                                key={model.code}
                              >
                                {userStore.isLexusUser()
                                  ? model.description
                                  : model.grade}
                              </AccessoriesTableTrimHeaderName>
                            ))}
                          </AccessoriesTableHeaderRow>
                          <AccessoriesTableHeaderRow>
                            {modelItemList.map((model) => (
                              <div key={model.code}>
                                <AccessoriesTableHeaderDetail
                                  className={styles.modelApplicabilityHeader}
                                  modelCode={model.code}
                                >
                                  <div>{model.code}</div>
                                </AccessoriesTableHeaderDetail>

                                <SyncAllChangesContext.Provider
                                  value={{
                                    state: stateSyncAllChanges,
                                    dispatch: dispatchSyncAllChanges,
                                  }}
                                >
                                  <GoLiveDate
                                    readOnly={readOnly}
                                    model={model}
                                  />
                                </SyncAllChangesContext.Provider>
                              </div>
                            ))}
                          </AccessoriesTableHeaderRow>
                        </tr>
                      </Thead>
                    </Table>
                  </div>
                </ScrollSyncPane>
                <ResizeDetector
                  handleHeight
                  onResize={(width: number, height: number) => {
                    onResize(width, height - 1);
                  }}
                  targetDomEl={
                    ref === null ? undefined : (ref.current as HTMLElement)
                  }
                />
              </StickyHeaderSection>

              {/* Model App body */}
              <ScrollSyncPane group="horizontal">
                <div>
                  <Table>
                    <tbody>
                      {filteredData.map((accessory) => (
                        <TableRow
                          key={accessory.uid}
                          className={styles.bodyRow}
                        >
                          <>
                            <>
                              {modelItemList.map((model, i2) => {
                                const key = i2 + 1;
                                return (
                                  <CheckboxCell
                                    key={`key${model.code}${accessory.id}${key}`}
                                    id={`chboxGrd${model.code}${accessory.id}${key}`}
                                    disabled={cantEditEN}
                                    checked={
                                      (accessory.modelApplicability &&
                                        accessory.modelApplicability.some(
                                          (maItem) =>
                                            maItem.modelId === model.modelId
                                        )) ||
                                      false
                                    }
                                    onClick={(a) => {
                                      let item = [
                                        ...(accessory.modelApplicability || []),
                                      ];
                                      if (a.currentTarget.checked) {
                                        item.push({
                                          modelId: model.modelId,
                                        } as ModelApplicabilityItem);
                                      } else {
                                        item =
                                          item.filter(
                                            (x) => x.modelId !== model.modelId
                                          ) || null;
                                      }
                                      const noParamReassign = accessory;
                                      noParamReassign.modelApplicability = item;
                                      saveAccessoryItem(accessory);
                                    }}
                                  >
                                    {i2 === 0 && canEditEN && (
                                      <div className={styles.wrapper}>
                                        <div
                                          className={styles.applyAllBtn}
                                          role="button"
                                          tabIndex={0}
                                          onKeyPress={() => {
                                            toggleAll(accessory);
                                          }}
                                          onClick={() => {
                                            return (
                                              canEditEN && toggleAll(accessory)
                                            );
                                          }}
                                        >
                                          Apply all
                                        </div>
                                      </div>
                                    )}
                                  </CheckboxCell>
                                );
                              })}
                            </>
                          </>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </ScrollSyncPane>
            </RightTableSizer>
          </ScrollSync>
        </TwoTableWrapper>
      </StickyContainer>
    </AccessoriesEntryScreenContext.Provider>
  );
};

export default withRouter(observer(AccessoriesEntryScreenController));
