import React from "react";
import { ChecklistItem } from "vapi-ui-common/dist/src/hooks/useChecklist/useChecklist";
import { Button, SecondaryHeader } from "vapi-ui-common";
import { FilterTab, FilterTabColumns } from "../FilterTab";
import AccessoryFilters from "../AccessoryFilters";
import ModelFilters from "../ModelFilters";
import FilterTabFooter from "../FilterTab/FilterTabFooter";
import VehicleModelsStore from "../../stores/modelStore";
import { FILTERTYPE } from "../../constants/Constants";
import styles from "./filterDropdown.module.scss";

export interface FilterDropdownProps {
  onClose: () => void;
  modelStore: VehicleModelsStore;
  isSpanish?: boolean;
  disableDio?: boolean;
  disablePpo?: boolean;
  selectedFilterTab: string;
  setSelectedFilterTab: (val: string) => void;
  checklist: ChecklistItem[];
  isAllSelected: boolean;
  selectAll: (val: boolean) => void;
  selectItem: (item: ChecklistItem, selected: boolean) => void;
  inProgress: boolean;
  setInProgress: (val: boolean) => void;
  active: boolean;
  setActive: (val: boolean) => void;
  dio?: boolean;
  setDio: (val: boolean) => void;
  ppo?: boolean;
  setPpo: (val: boolean) => void;
  syncChanges: boolean;
  setSyncChanges: (val: boolean) => void;
  selectedFuelTypes: string[];
  setSelectedFuelTypes: (list: string[]) => void;
  onCancelFilter: () => void;
  onClickFilter: () => void;
}

const FilterDropdown = ({
  onClose,
  modelStore,
  isSpanish,
  disableDio,
  disablePpo,
  selectedFilterTab,
  setSelectedFilterTab,
  checklist,
  isAllSelected,
  selectAll,
  selectItem,
  inProgress,
  setInProgress,
  active,
  setActive,
  dio = false,
  setDio,
  ppo = false,
  setPpo,
  syncChanges,
  setSyncChanges,
  selectedFuelTypes,
  setSelectedFuelTypes,
  onCancelFilter,
  onClickFilter,
}: FilterDropdownProps) => {
  const onCancel = () => {
    onCancelFilter();
    onClose();
  };

  const onClick = () => {
    onClickFilter();
    onClose();
  };

  return (
    <div className={styles.filterContainer} data-testid="filter-dropdown-comp">
      <SecondaryHeader
        tabs={[FILTERTYPE.ACCESSORIES, FILTERTYPE.MODELMATRIX]}
        selectedTab={selectedFilterTab}
        setSelectedTab={setSelectedFilterTab}
        renderButtons={() => <></>}
        className={styles.secondaryHeader}
      />
      <FilterTab>
        <FilterTabColumns>
          {selectedFilterTab === FILTERTYPE.ACCESSORIES && (
            <AccessoryFilters
              checklist={checklist}
              isAllSelected={isAllSelected}
              isSpanish={isSpanish}
              disableDio={disableDio}
              disablePpo={disablePpo}
              selectAll={selectAll}
              selectItem={selectItem}
              inProgress={inProgress}
              setInProgress={setInProgress}
              active={active}
              setActive={setActive}
              dio={dio}
              setDio={setDio}
              ppo={ppo}
              setPpo={setPpo}
              syncChanges={syncChanges}
              setSyncChanges={setSyncChanges}
            />
          )}
          {selectedFilterTab === FILTERTYPE.MODELMATRIX && (
            <ModelFilters
              fuelTypes={modelStore.fuelTypes}
              selectedFuelTypes={selectedFuelTypes}
              setSelectedFuelTypes={setSelectedFuelTypes}
            />
          )}
        </FilterTabColumns>
        <FilterTabFooter>
          <Button variant="transparent" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onClick}>
            Apply Filters
          </Button>
        </FilterTabFooter>
      </FilterTab>
    </div>
  );
};

export default FilterDropdown;
