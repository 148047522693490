import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { toast } from "react-toastify";
import { getToken } from "../authConfig";

const API: AxiosInstance = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const requestHandler = async (request: AxiosRequestConfig) => {
  const token = await getToken();
  if (request.headers) {
    request.headers.Authorization = `Bearer ${token}`;
  }
  return request;
};

const responseHandler = (response: AxiosResponse<any>) => {
  return response;
};

const responseRejectedHandler = (error: AxiosError<{ message?: string }>) => {
  switch (error.config?.method || "") {
    case "delete":
    case "post":
    case "put": {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      }
      break;
    }
    default: {
      break;
    }
  }
  return Promise.reject(error);
};

API.interceptors.request.use(requestHandler);
API.interceptors.response.use(responseHandler, responseRejectedHandler);

export default API;
