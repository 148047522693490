import React from "react";
import { AccessoryItem, AclChange, Change } from "../../../gql/generated";
import {
  ChangeLogBase,
  ChangeLogTypes,
} from "../../../models/accessories/changeLog.model";
import ChangeLogRow from "../../ChangeLogTable/ChangeLogRow";
import ChangeLogChanges from "../../ChangeLogTable/ChangeLogChanges";

export interface RowChangesProps {
  changes: (Change | AclChange)[];
}

const ADSRowChanges = ({ changes }: RowChangesProps) => {
  const getChanges = () => {
    return changes.map((change: Change | AclChange, i: number) => {
      const accItem = change.changedItem as AccessoryItem;
      const changeType: ChangeLogTypes =
        Object.values(ChangeLogTypes).find(
          (val) => val.toLocaleLowerCase() === change.changeType.toLowerCase()
        ) ?? ChangeLogTypes.COPY;

      const item: ChangeLogBase = {
        id: accItem.id,
        revId: accItem.revId,
        before: change.before ?? "",
        after: change.after ?? "",
        beforeValue: change.before ?? "",
        afterValue: change.after ?? "",
        modifiedDate: "",
        modifiedBy: "",
        changeType,
      };

      const key = `ads_row_change_${i}`;

      return (
        <ChangeLogRow
          key={key}
          description={accItem.title}
          changeType={change.changeType}
          modifiedBy=""
          modifiedDate=""
          notes=""
          canRevert={false}
          hideModifiedBy
          hideModifyDate
          hideNotes
          hideRevert
          onRevert={() => {}}
          renderChanges={<ChangeLogChanges changeItem={item} />}
        />
      );
    });
  };

  return <>{getChanges()}</>;
};

export default ADSRowChanges;
