import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import React, { useMemo, useState, useEffect } from "react";
import {
  ActionBar,
  ActionBarSection,
  Button,
  Checkbox,
  CheckboxLabel,
  HeaderRow,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollableContainer,
  SearchInput,
  Spinner,
  Table,
  TableCell,
  TableRow,
} from "vapi-ui-common";
import {
  ACCESSORY_TEAM,
  GST_REGION,
  NATIONAL_REGION,
} from "../../constants/Constants";
import {
  AclDocument,
  useAclCurrentVersionDocumentsQuery,
} from "../../gql/generated";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import useStores from "../../stores/useStores";
import handleErrorResponse from "../../utils/errorHandlingUtils";
import AccessoryRichText from "../AccessoryRichText";
import styles from "./CommonLanguageLink.module.scss";
import { uncachedPartNumberImage } from "../../utils/partNumberImage";

interface CommonLanguageLinkModalProps {
  accessories: AccessoryItemVM[];
  onLink: (aclDoc: AclDocument) => void;
  close: () => void;
}

const CommonLanguageLinkModal = ({
  accessories,
  onLink,
  close,
}: CommonLanguageLinkModalProps) => {
  const { userStore } = useStores();
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<undefined | string>();
  const [aclResDocs, setAclResDocs] = useState<AclDocument[] | undefined>(
    undefined
  );
  const comLangSet = useMemo(
    () =>
      accessories.reduce((acc, item) => {
        return item.comLangId ? acc.add(item.comLangId) : acc;
      }, new Set<string>()),
    [accessories]
  );

  const queryCtx = {
    brand: userStore.brand,
    region: userStore.isGstUser() ? GST_REGION : NATIONAL_REGION,
    team: ACCESSORY_TEAM,
  };

  const aclRes = useAclCurrentVersionDocumentsQuery({
    skip: !queryCtx.brand || !queryCtx.region,
    fetchPolicy: "network-only",
    variables: queryCtx,
    onError: (e) => {
      handleErrorResponse(e);
    },
  });

  useEffect(() => {
    if (aclRes) {
      setAclResDocs(aclRes.data?.aclCurrentVersionDocuments);
    }
  }, [aclRes]);

  const getDescription = (item: AclDocument) => {
    return userStore.isGstUser()
      ? item.accessory.description ?? ""
      : item.accessory.copy ?? "";
  };

  useEffect(() => {
    let thisAclDocs = aclRes.data?.aclCurrentVersionDocuments;

    if (search) {
      thisAclDocs = aclRes.data?.aclCurrentVersionDocuments.filter(
        (item) =>
          item.accessory.title.toLowerCase().includes(search.toLowerCase()) ||
          getDescription(item).toLowerCase().includes(search.toLowerCase()) ||
          item.accessory.ppoCode
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          item.accessory.warranty
            ?.toLocaleLowerCase()
            .includes(search.toLowerCase())
      );
    }

    setAclResDocs(thisAclDocs);
  }, [search]);

  const handleLink = () => {
    const aclDoc = aclRes.data?.aclCurrentVersionDocuments.find(
      ({ sk }) => sk === selected
    );
    if (aclDoc) {
      close();
      setSelected(undefined);
      setSearch("");
      onLink(aclDoc);
    }
  };

  return (
    <>
      <ModalHeader onClose={close}>Link Common Language</ModalHeader>
      <ModalBody>
        {aclRes.loading ? (
          <Spinner short />
        ) : (
          <>
            <ActionBar>
              <ActionBarSection>
                <SearchInput
                  value={search}
                  onSearch={(newValue) => setSearch(newValue)}
                />
              </ActionBarSection>
            </ActionBar>
            <ScrollableContainer style={{ overflow: "scroll" }}>
              <Table fullWidth className={styles.table}>
                <thead>
                  <HeaderRow className={styles.stickyNav}>
                    <TableCell noSpan />
                    <TableCell noSpan>Name</TableCell>
                    <TableCell noSpan>Copy</TableCell>
                    {userStore.isNationalUser() && (
                      <TableCell noSpan>PPO Code</TableCell>
                    )}
                    <TableCell noSpan>Warranty</TableCell>
                    {userStore.isNationalUser() && (
                      <TableCell noSpan>Image</TableCell>
                    )}
                  </HeaderRow>
                </thead>

                <tbody>
                  {aclResDocs?.map((item) => (
                    <TableRow zebra key={item.sk}>
                      {/* checkbox */}
                      <TableCell noSpan>
                        <Checkbox
                          id={`addCl-chbox-${item.sk}`}
                          checked={selected === item.sk}
                          onChange={(e) => {
                            setSelected(
                              e.currentTarget.checked ? item.sk : undefined
                            );
                          }}
                          disabled={comLangSet.has(item.sk)}
                          labelClassName={clsx({
                            [styles.disabled]: comLangSet.has(item.sk),
                          })}
                        />
                      </TableCell>

                      {/* Accessory Name */}
                      <TableCell noSpan>
                        <AccessoryRichText
                          value={item.accessory.title}
                          disabled
                        />
                      </TableCell>

                      {/* Copy */}
                      <TableCell noSpan>
                        <AccessoryRichText
                          value={
                            userStore.isGstUser()
                              ? item.accessory.description
                              : item.accessory.copy
                          }
                          disabled
                        />
                      </TableCell>

                      {/* PPO Code */}
                      {userStore.isNationalUser() && (
                        <TableCell noSpan>
                          {!item.accessory.installPoint?.includes("DIO") ? (
                            <AccessoryRichText
                              value={item.accessory.ppoCode}
                              disabled
                            />
                          ) : (
                            <span>DIO</span>
                          )}
                        </TableCell>
                      )}

                      {/* Warranty */}
                      <TableCell noSpan>
                        {userStore.isNationalUser() && (
                          <Checkbox
                            id={`checkbox-aap-${item.sk}`}
                            checked={item.accessory.isNonGenAccessory ?? false}
                            disabled
                            labelClassName={clsx({
                              [styles.disabled]:
                                item.accessory.isNonGenAccessory,
                            })}
                          >
                            <CheckboxLabel>AAP</CheckboxLabel>
                          </Checkbox>
                        )}
                        <AccessoryRichText
                          value={item.accessory.warranty}
                          disabled
                        />
                      </TableCell>

                      {/* Image */}
                      {userStore.isNationalUser() && (
                        <TableCell noSpan>
                          <img
                            className={styles.img}
                            src={uncachedPartNumberImage(
                              item.accessory.images?.find((el) => el.isHero)
                                ?.image
                            )}
                            alt=""
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </ScrollableContainer>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={close}>
          Cancel
        </Button>
        <Button variant="primary" disabled={!selected} onClick={handleLink}>
          Link Common Language
        </Button>
      </ModalFooter>
    </>
  );
};

interface CommonLanguageLinkProps {
  accessories: AccessoryItemVM[];
  onLink: (aclDoc: AclDocument) => void;
}

const CommonLanguageLink = ({
  accessories,
  onLink,
}: CommonLanguageLinkProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        className={styles.commonLanguageLink}
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faLink} />
      </Button>

      <Modal size="auto" open={showModal} onClose={() => setShowModal(false)}>
        <CommonLanguageLinkModal
          accessories={accessories}
          onLink={onLink}
          close={() => setShowModal(false)}
        />
      </Modal>
    </>
  );
};

export default CommonLanguageLink;
