import React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "vapi-ui-common";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";

interface ICommonLanguageBadgeProps {
  accessoryItem: AccessoryItemVM;
  onUnlink: () => void;
  onClose: () => void;
}

const CommonLanguageUnlinkModal = ({
  accessoryItem,
  onUnlink,
  onClose,
}: ICommonLanguageBadgeProps) => {
  return (
    <>
      <ModalHeader onClose={onClose}>Unlink Common Language</ModalHeader>
      <ModalBody>
        {"Are you sure you want to unlink Common Language for "}
        <b>{`${JSON.parse(accessoryItem.title).text}?`}</b>
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onUnlink}>
          Unlink Common Language
        </Button>
      </ModalFooter>
    </>
  );
};

export default CommonLanguageUnlinkModal;
